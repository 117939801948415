<template>
  <div class="home-case">
    <div class="container">
      <div class="comm-title">{{ $t('pageHome.case.title') }}</div>
      <div class="case-tab">
        <span class="split-line-left"/>
        <div class="case-tab-item" :class="{active:active===0}" @click="active=0">
          <div class="item-inner">
            <i class="icon-wrap icon-kj"></i>
            <div class="name" :class="{en:lang==='en'}">{{ $t('pageHome.case.kj') }}</div>
          </div>
        </div>
        <span class="split-line-center"/>
        <div class="case-tab-item" :class="{active:active===1}" @click="active=1">
          <div class="item-inner">
            <i class="icon-wrap icon-xf"/>
            <div class="name" :class="{en:lang==='en'}">{{ $t('pageHome.case.xf') }}</div>
          </div>
        </div>
        <span class="split-line-center"/>
        <div class="case-tab-item" :class="{active:active===2}" @click="active=2">
          <div class="item-inner">
            <i class="icon-wrap icon-yx"/>
            <div class="name" :class="{en:lang==='en'}">{{ $t('pageHome.case.yx') }}</div>
          </div>
        </div>
        <span class="split-line-right"/>
      </div>
      <div class="case-content">
        <case-list v-if="active===0" :list="CaseKJ" :show-detail="false"/>
        <case-list v-if="active===1" :list="CaseXF" :show-detail="false"/>
        <case-list v-if="active===2" :list="CaseYX" :show-detail="false"/>
      </div>
      <div class="case-bottom">
        <a class="more" href="#/case">{{ $t('pageHome.case.more') }}<i class="icon-more"/></a>
      </div>
    </div>
  </div>
</template>

<script>
import {CaseKJ, CaseXF, CaseYX} from '@/utils/const'
import CaseList from "../CaseList";
import {mapState} from "vuex/dist/vuex.esm.browser";

export default {
  name: "HomeCase",
  components: {CaseList},
  data() {
    return {
      active: 0,
      CaseKJ: CaseKJ.slice(0, 5),
      CaseXF: CaseXF.slice(0, 5),
      CaseYX: CaseYX.slice(0, 5)
    }
  },
  computed: {
    ...mapState(['lang'])
  }
}
</script>

<style lang="scss" scoped>
.home-case {
  height: 633px;
  box-sizing: border-box;
  padding-top: 52px;

  .case-tab {
    display: flex;
    align-items: center;
    margin-top: 38px;
    margin-bottom: 34px;

    .split-line-left {
      width: 148px;
      height: 15px;
      background: url("./images/line-01.png") no-repeat center;
      background-size: 100% 100%;
      margin-right: 6px;
    }

    .split-line-center {
      width: 302px;
      height: 15px;
      background: url("./images/line-02.png") no-repeat center;
      background-size: 100% 100%;
      margin-left: 6px;
      margin-right: 6px;
    }

    .split-line-right {
      width: 148px;
      height: 15px;
      background: url("./images/line-04.png") no-repeat center;
      background-size: 100% 100%;
      margin-left: 6px;
    }

    .case-tab-item {
      width: 160px;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .item-inner {
        width: 120px;
        height: 120px;
        background: url("./images/item-bg-default.png") no-repeat;
        background-size: 100% 100%;
        padding-top: 10px;
        box-sizing: border-box;

        .icon-wrap {
          width: 64px;
          height: 64px;
          display: block;
          margin: 0 auto 8px auto;

          &.icon-kj {
            background: url("./images/icon-kj-default.png") no-repeat;
            background-size: 100% 100%;
          }

          &.icon-xf {
            background: url("./images/icon-xf-default.png") no-repeat;
            background-size: 100% 100%;
          }

          &.icon-yx {
            background: url("./images/icon-yx-default.png") no-repeat;
            background-size: 100% 100%;
          }
        }

        .name {
          font-weight: 400;
          font-size: 20px;
          color: #282828;
          line-height: 28px;
          text-align: center;

          &.en {
            font-size: 16px;
            margin-top: -4px;
          }
        }
      }

      &.active {
        background: url("./images/item-bg-active.png") no-repeat;
        background-size: 100% 100%;

        .item-inner {
          background: none;

          .icon-wrap {
            &.icon-kj {
              background: url("./images/icon-kj-active.png") no-repeat;
              background-size: 100% 100%;
            }

            &.icon-xf {
              background: url("./images/icon-xf-active.png") no-repeat;
              background-size: 100% 100%;
            }

            &.icon-yx {
              background: url("./images/icon-yx-active.png") no-repeat;
              background-size: 100% 100%;
            }
          }

          .name {
            color: #C8171E;
          }
        }
      }
    }
  }

  .case-bottom {
    display: flex;
    justify-content: flex-end;

    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 214px;
      height: 40px;
      background: #C7171E;
      color: #FFFFFF;
      font-size: 14px;

      &:hover {
        text-decoration: none;
        opacity: 0.9;
      }

      .icon-more {
        display: block;
        width: 96px;
        height: 9px;
        margin-left: 18px;
        background: url("./images/icon-more-arrow.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
